<!-- eslint-disable no-unused-vars -->
<template>
  <b-card title="" class="text-left">
    <b-form>
      <!-- Projeto -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="projectSelect" class="text-center font-weight-bold text-primary"> Projeto : </label>

            <div @click="handleShowArquived()" :style="{ color: 'blue', padding: '5px 0', cursor: 'pointer' }">
              {{ showArquivded ? 'Ocultar arquivados' : 'Mostrar arquivados' }}
            </div>

            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="item.project_id"
              :options="projects"
              value-field="id"
              text-field="name"
              placeholder="Selecione um projeto"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Valor -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="value" class="text-center font-weight-bold text-primary">Valor :</label>

            <b-input-group>
              <BFormInput id="value" type="text" v-model="item.value" v-money="moneyMask"></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Data Refrencia -->

      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="date" class="font-weight-bold text-primary">Data de Referência :</label>
            <BFormInput id="date" type="date" v-model="item.reference_date"></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Data Transação -->

      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="date" class="font-weight-bold text-primary">Data de Transação :</label>
            <BFormInput id="date" type="date" v-model="item.transaction_date"></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Status -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="status" class="font-weight-bold text-primary">Status :</label>
            <b-form-select
              id="status"
              v-model="item.status"
              :options="statusOptions"
              placeholder="Selecione o status"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Descrição -->

      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="description" class="font-weight-bold text-primary">Descrição :</label>
            <BFormTextarea id="description" type="textarea" v-model="item.description"></BFormTextarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Tipo -->
    <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
      <b-col col xs="12" sm="12" md="6" lg="6">
        <b-form-group>
          <label for="status" class="font-weight-bold text-primary">Tipo :</label>
          <b-form-select
            id="status"
            v-model="item.type"
            :options="typesOptions"
            placeholder="Selecione o status"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Se Parcelado -->
    <div v-if="item.type == 'parcelado' && item.value">
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <b-input-group prepend="Parcelas">
              <BFormInput id="installmentsInput" type="number" v-model="installmentsInput"></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Parcelas -->
      <div v-if="item.value && installmentsInput > 0">
        <div v-for="i in parseInt(installmentsInput)" :key="i">
          <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <b-input-group prepend="R$">
                  <BFormInput
                    :id="`installment-value`"
                    type="text"
                    :ref="`installments-value-${i}`"
                    :value="numberToMonetary(monetaryToNumber(item.value) / parseInt(installmentsInput))"
                  ></BFormInput>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group prepend="">
                <BFormInput
                  :id="`installment-date`"
                  :ref="`installments-date-${i}`"
                  type="date"
                  :value="installmentIncrease(i)"
                ></BFormInput>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <!-- Se Repetir -->

    <!-- Botoes -->
    <div class="justify-center">
      <BButton variant="outline" @click="$router.push('/lancamento-de-receita')">Voltar</BButton>
      <BButton @click="saveItem">Salvar</BButton>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BFormSelect,
  BInputGroup
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';
import moment from 'moment';

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BButton,
    BInputGroup
  },
  data: () => ({
    item: {
      project_id: null,
      type: 'a vista',
      status: 'previsto',
      value: 0,
      repeat: 0,
      transaction_date: moment().format('YYYY-MM-DD'),
      reference_date: moment().format('YYYY-MM-DD')
    },
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false
    },
    installments: [],
    projects: [],
    installmentsInput: 2,
    statusOptions: ['previsto', 'realizado'],
    typesOptions: ['a vista', 'parcelado'],
    showArquivded: false,
    instalment_date_increase: moment().format('YYYY-MM-DD')
  }),

  created() {
    this.getProjects();
  },

  computed: {},

  methods: {
    async getProjects() {
      this.$store
        .dispatch('_getProjects', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id
        })
        .then(resp => {
          resp.data.map(e => {
            if (this.showArquivded) {
              this.projects.push({
                id: e.id,
                name: `${e.project_code} - ${e.project_name} ${e.is_archived ? '(Arquivado)' : ''}`
              });
            } else {
              if (e.is_archived) {
                return null;
              } else {
                this.projects.push({
                  id: e.id,
                  name: `${e.project_code} - ${e.project_name} ${e.is_archived ? '(Arquivado)' : ''}`
                });
              }
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    },

    async saveItem() {
      if (this.item.type === 'parcelado' && this.installmentsInput > 0) {
        for (let i = 1; i <= parseInt(this.installmentsInput); i++) {
          const valueInstallments = this.monetaryOnlyCommas(this.$refs[`installments-value-${i}`][0].value);
          console.log('PARCELA: ', this.$refs[`installments-value-${i}`][0].value);
          console.log('PARCELA convertida: ', valueInstallments);

          const dateInstallments = this.$refs[`installments-date-${i}`][0].$refs.input.value;

          this.installments.push({
            value: valueInstallments,
            transaction_date: dateInstallments
          });
        }
      }

      this.$store
        .dispatch('saveRevenue', {
          ...this.item,
          description: this.item.description ? this.item.description : 'Sem descrição',
          value: this.monetaryToNumber(this.item.value),
          installments: this.installments,
          workspace_id: this.$store.getters.currentWorkspace.id
        })
        .then(resp => {
          console.log(resp);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Receita alterada com sucesso.',
              icon: 'SuccessIcon',
              variant: 'success'
            }
          });
          this.$router.push('/lancamento-de-receita');
        })
        .catch(error => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao alterar receita.',
              icon: 'XIcon',
              variant: 'danger'
            }
          });
        });
    },

    installmentIncrease(i) {
      let installmentDate = moment();
      installmentDate.add(i, 'months');

      return installmentDate.format('YYYY-MM-DD');
    },

    async createInstallmentsLength() {
      if (this.installments.length < this.installmentsInput) {
        for (let i = this.installments.length; i < this.installmentsInput; i++) {
          this.installments.push({ value: 0 });
        }
      } else if (this.installments.length > this.installmentsInput && this.installmentsInput !== 0) {
        for (let i = this.installments.length - 1; i >= this.installmentsInput; i--) {
          console.log('minus');
          this.installments.pop();
        }
      }

      console.log(this.installments);
    },

    handleShowArquived() {
      this.showArquivded = !this.showArquivded;
      this.projects = [];
      this.getProjects();
    }
  }
};
</script>
<style>
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}
</style>
